<template>
  <div
    data-cy="hero-v2-container"
    class="hero-v2 relative bg-secondary pt-6 md:pt-10 pb-6 md:pb-10"
  >

    <img v-if="data.imageRetina" class="absolute top-0 object-cover object-top w-full h-full"
      :alt="data.imageAlt ? data.imageAlt : ''"
      :aria-hidden="data.imageAlt ? null : 'true'"
      :src="`${prodUrl}/cdn-cgi/image/f=auto,width=375,height=800,fit=cover,quality=75${data.image}`"
      :srcset="`
        ${prodUrl}/cdn-cgi/image/f=auto,width=375,height=800,fit=cover,quality=75${data.image} 375w,
        ${prodUrl}/cdn-cgi/image/f=auto,width=800,height=1600,fit=cover,quality=75${data.image} 800w,
        ${prodUrl}/cdn-cgi/image/f=auto,width=1200,height=1200,fit=cover,quality=75${data.image} 1200w,
        ${prodUrl}/cdn-cgi/image/f=auto,width=1536,height=1600,fit=cover,quality=75${data.image} 1536w,
        ${prodUrl}/cdn-cgi/image/f=auto,width=1440,height=800,fit=cover,quality=75${data.image} 1440w,
        ${prodUrl}/cdn-cgi/image/f=auto,width=2880,height=1600,fit=cover,quality=75${data.image} 2880w
      `"
      fetchpriority="high"
    >
    <img v-else class="absolute top-0 object-cover object-top w-full h-full"
      :alt="data.imageAlt ? data.imageAlt : ''"
      :aria-hidden="data.imageAlt ? null : 'true'"
      :src="`${prodUrl}/cdn-cgi/image/f=auto,width=375,height=800,fit=cover,quality=75${data.image}`"
      :srcset="`
        ${prodUrl}/cdn-cgi/image/f=auto,height=630,quality=75${data.image.replace('.jpg','-sm.jpg')} 295w,
        ${prodUrl}/cdn-cgi/image/f=auto,height=600,quality=75${data.image.replace('.jpg','-md.jpg')} 576w,
        ${prodUrl}/cdn-cgi/image/f=auto,width=1440,quality=75${data.image.replace('.jpg','-lg.jpg')} 1440w
      `"
      fetchpriority="high"
    >

    <!-- hero takeover -->
    <a
      id="campaignHero__takeover-link"
      class="cursor-pointer"
      style="display:none"
      aria-hidden="true"
      tabindex="-1"
      @click.prevent="takeoverAdClick"
    >
      <span id="campaignHero__takeover-desc" class="sr-only"></span>
      <img
        id="campaignHero__takeover-image"
        class="absolute top-0 object-cover object-top w-full h-full"
        alt=""
        aria-hidden="true"
      />
    </a>
    <!-- /hero takeover -->

    <div class="container relative pointer-events-none">
      <h1
        data-cms-text="heroV2.title"
        data-cy="hero-v2-title"
        class="w-full leading-tight text-2xl md:text-3xl font-semibold text-white pointer-events-auto"
        :class="[channel === 'holidays' || data.text ? 'mb-2' : 'mb-6']"
      >
        {{ data.title }}
      </h1>

      <h2
        v-if="channel === 'holidays' || data.text"
        class="w-full mb-6 text-lg md:text-xl text-white"
      >
        <span v-if="this.cheapestPrice"
              data-cms-text="heroV2.subtitle"
              data-cy="hero-v2-subtitle">
          Holidays from £{{ this.cheapestPrice }} per person
        </span>
        <span v-else-if="data.text"
              data-cms-text="heroV2.text">
          {{data.text}}
        </span>
        <span v-else>&nbsp;</span>
      </h2>

      <div v-for="(comp, idx) in data.components"
          :key="`hero-${idx}-component`"
          :data-cms-parent="`heroV2.components.${idx}`"
          class="relative pointer-events-auto p-2 -m-2 md:p-4 md:-m-4"
          :class="{'z-2': idx === 0, 'z-1' : idx !== 0}">
        <component
          :is="Object.keys(comp)[0]"
          :ref="Object.keys(comp)[0]"
          :data="comp[Object.keys(comp)[0]]"
        />
      </div>

      <CampaignBannerHero></CampaignBannerHero>
    </div>
  </div>
</template>

<script>
import components from "@/components";
import { mapGetters } from 'vuex'

export default {
  name: "HeroV2",
  components: components,
  props: {
    data: Object
  },
  data() {
    return {
      prodUrl: 'https://www.travelsupermarket.com', // Cloudflare images always need to be served from the live domain
    }
  },
  computed: {
    ...mapGetters(['cheapestPrice', 'channel'])
  },
  methods: {
    takeoverAdClick(){
      let obj = {
        ga: "campaignTakeoverImageClick",
        ga4: "homepage takeover"
      }
      this.$root.$emit("takeoverImageClick", obj);
    }
  },
  beforeMount() {
      this.$root.$on("heroTabClick", (newHeroTitle) => {
        this.data.title = newHeroTitle;
    });
  }
};
</script>

<style lang="scss">
  .hero-v2__shapes {
    min-height: 520px;
    @screen md {
      min-height: 600px;
    }
    @screen lg {
      min-height: 360px;
    }
  }

  .hero-v2 {
    background-color: transparent;
    min-height: 530px;
    @screen md {
      min-height: 630px;
    }
    @screen lg {
      min-height: 390px;
    }
    @screen xl {
      min-height: 360px;
    }
  }

  .hero-v2__circle {
    border-radius: 0 0 50% 50%;
    border-width: 100px;
    border-top-width: 0;
    bottom: -65px;
    height: 120vw;
    left: 50%;
    margin-left: -100vw;
    width: 200vw;
    @screen md {
      border-width: 100px;
      border-top-width: 0;
      bottom: -90px;
    }
    @screen lg {
      border-width: 300px;
      bottom: -295px;  
      height: 100vw;
      left: 50%;
      margin-left: -103vw;
      width: 206vw;
    }
  }

  .hero-v2__circle--orange {
    bottom: -60px;
    transform: rotate(-4deg);
    @screen md {
      bottom: -90px;
    }
    @screen lg {
      bottom: -325px;
      height: 110vw;
    }
  }

  .hero-v2__circle--light {
    bottom: -55px;
    -webkit-transform: rotate(-8deg);
    transform: rotate(-5deg);
    margin-left: -110vw;
    @screen md {
      bottom: -95px;
    }
    @screen lg{
      bottom: -345px;
      height: 110vw;
    }
  }
</style>